import React from 'react'
import Layout from '../components/layout'
import Footer from '../components/footer'
import Preview from '../components/Preview'
import { Link } from 'gatsby'


const About = props => (
    <Layout>
        <Preview />

        <div class="grid md:grid-cols-3 md:w-10/12 2xl:w-7/12 px-4 mx-auto py-4 md:pt-24 gap-4">
            <div class="md:col-span-1">
                <p data-sal="slide-up"
                    data-sal-duration="900"
                    data-sal-delay="200"
                    data-sal-easing="ease"
                    class="heading subpixel-antialiased text-3xl md:text-4xl font-semibold text-left text-theme-text md:pt-0 md:leading-normal">
                    Creative, innovative, self starter.</p>

            </div>

            <div class="md:col-span-1">
                <p data-sal="slide-up"
                    data-sal-duration="800"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    class="mx-auto md:w-12/12 subpixel-antialiased text-base md:text-l font-base text-left leading-7">
                    <a href='https://www.linkedin.com/in/badarao/' target="_blank" rel="noreferrer" class="underline hover:text-orange-600">7+ years experience</a> collaborating in cross-functional teams to develop products in health, education, travel, privacy, online trust, and private equity.</p>


            </div>


            <div class="md:col-span-1">
                <p data-sal="slide-up"
                    data-sal-duration="800"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    class="mx-auto md:w-12/12 subpixel-antialiased text-base md:text-l font-base text-left leading-7">
                    My current role focuses on designing equity management and compliance solutions for UK businesses at <a class="underline hover:text-orange-600" href="https://carta.com/uk/en/" target="_blank" rel="noreferrer">Carta</a>.
                </p>


            </div>

        </div>

        <div class="grid md:grid-cols-3 md:w-10/12 2xl:w-7/12 px-4 mx-auto gap-4">
            <div class="md:col-span-1">
            </div>

            <div class="md:col-span-2">
                <p data-sal="slide-up"
                    data-sal-duration="800"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    class="mx-auto subpixel-antialiased text-base md:text-l font-base text-left leading-7">
                    In my current design workflow, I leverage 1-1 research, competitive analysis, an in-house design system on Figma, insights from Hotjar or Fullstory as well as product analytics to make design decisions. </p>

                <p data-sal="slide-up"
                    data-sal-duration="800"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    class="mx-auto subpixel-antialiased text-base md:text-l font-base text-left leading-7 pt-2">
                    When I am not pushing pixels or talking to users, I enjoy traveling, listening to podcasts and learning about historical events that have shaped countries around the world.

                </p>
            </div>




        </div>

        <div id="section-2" class="bg-secondary mt-12 bg-opacity-30 py-8 md:py-12">
            <div class="md:px-0 md:w-10/12 2xl:w-7/12 mx-auto">
                <p data-sal="slide-up"
                    data-sal-duration="700"
                    data-sal-delay="200"
                    data-sal-easing="ease" class="heading px-4 subpixel-antialiased font-semibold text-left text-theme-text pb-4 text-2xl md:text-3xl leading-9">📸 Some photos inspired by my travel </p>

            </div>


            <div class="md:flex flex-row md:w-10/12 2xl:w-7/12 px-4 mx-auto md:pt-4 gap-6">

                <div class="relative mb-4 md:pb-0">
                    <div class="absolute py-1.5 px-2 bg-gray-100 bg-opacity-70 bottom-0 rounded-sm m-4"> <span class="flex gap-2">
                        <img src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668967216/FlagsSweden.svg"
                            class=""
                            alt="flag" /> <p class="text-sm text-gray-700 font-semibold">Malmö, Sweden</p>
                    </span></div>
                    <img src="https://res.cloudinary.com/dcj89ranh/image/upload/v1656259462/PXL_20220624_121847284.jpg"
                        class="rounded-md h-full"
                        alt="photograph" /></div>

                <div class="relative mb-4 md:pb-0">
                    <div class="absolute py-1.5 px-2 bg-gray-100 bg-opacity-70 bottom-0 rounded-sm m-4"> <span class="flex gap-2">
                        <img src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668965051/FlagsFrance.svg"
                            class=""
                            alt="flag" /> <p class="text-sm text-gray-700 font-semibold">Marseille, France</p>
                    </span></div>
                    <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/v1656259784/IMG_20200307_152637.jpg"
                        class="rounded-md h-full"
                        alt="photograph" /></div>



            </div>


            <div class="md:flex flex-row md:w-10/12 2xl:w-7/12 px-4 mx-auto md:pt-4 gap-6">


                <div class="relative mb-4 md:pb-0">
                    <div class="absolute py-1.5 px-2 bg-gray-100 bg-opacity-70 bottom-0 rounded-sm m-4"> <span class="flex gap-2">
                        <img src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668967217/FlagsNigeria.svg"
                            class=""
                            alt="flag" /> <p class="text-sm text-gray-700 font-semibold">Lagos, Nigeria</p>
                    </span></div><img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/v1656260650/IMG_20191230_134759.jpg"
                        class="rounded-md h-full"
                        alt="photograph" /></div>

                <div class="relative mb-4 md:pb-0">
                    <div class="absolute py-1.5 px-2 bg-gray-100 bg-opacity-70 bottom-0 rounded-sm m-4"> <span class="flex gap-2">
                        <img src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668967217/FlagsRwanda.svg"
                            class=""
                            alt="flag" /> <p class="text-sm text-gray-700 font-semibold">Kigali, Rwanda</p>
                    </span></div><img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/v1656260341/IMG_20191121_173024.jpg"
                        class="rounded-md h-full"
                        alt="photograph" /></div>


            </div>

            <div class="md:flex flex-row md:w-10/12 2xl:w-7/12 px-4 mx-auto md:pt-4 gap-6">
                <div class="relative mb-4 md:pb-0">
                    <div class="absolute py-1.5 px-2 bg-gray-100 bg-opacity-70 bottom-0 rounded-sm m-4"> <span class="flex gap-2">
                        <img src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668965051/FlagsFrance.svg"
                            class=""
                            alt="flag" /> <p class="text-sm text-gray-700 font-semibold">Marseille, France</p>
                    </span></div><img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/v1656260826/IMG_20200306_185621.jpg"
                        class="rounded-md h-full"
                        alt="photograph" /></div>
                <div class="relative mb-4 md:pb-0">
                    <div class="absolute py-1.5 px-2 bg-gray-100 bg-opacity-70 bottom-0 rounded-sm m-4"> <span class="flex gap-2">
                        <img src="https://res.cloudinary.com/dcj89ranh/image/upload/v1668965051/FlagsFrance.svg"
                            class=""
                            alt="flag" /> <p class="text-sm text-gray-700 font-semibold">Marseille, France</p>
                    </span></div><img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/v1656260953/PXL_20211009_161450432.jpg"
                        class="rounded-md h-full"
                        alt="photograph" /></div>




            </div>


        </div>

        <Footer />
        <div class="w-auto md:w-5/12 2xl:w-4/12 mx-auto md:px-4 overflow-hidden sticky bottom-0 md:bottom-8 z-200">
      <div data-sal="slide-up"
        data-sal-duration="400"
        data-sal-delay="1000"
        data-sal-easing="ease" class="flex justify-center sal-animate">
        <Link to="../#case-studies" class="group hover:bg-orange-900 transform transition duration-500 ease-in-out flex flex-col md:w-40 w-96 pb-1.5 bg-orange-800 shadow-sm border-2 border-orange-900 hover:border-transparent rounded-lg rounded-r-none">
          <div class="bg-orange-600 bg-opacity-50 rounded-lg rounded-r-none border-b-2 border-orange-900 text-plain font-semibold group-hover:bg-orange-700 "><p class="mx-auto text-center py-2">Work</p></div>

        </Link>
        
        <Link to="../#experiments" class="group hover:bg-orange-900 transform transition duration-500 ease-in-out flex flex-col md:w-40 w-96 pb-1.5 bg-orange-800 shadow-sm border-2 border-orange-900 hover:border-transparent rounded-lg rounded-l-none border-l-0">
          <div class="bg-orange-600 bg-opacity-50 rounded-lg rounded-l-none border-b-2 border-orange-900 text-plain font-semibold ry group-hover:bg-orange-700 "><p class="mx-auto text-center py-2">Experiments</p></div>

        </Link>

      </div>
    </div>


    </Layout >
)

export default About